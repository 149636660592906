<template>
  <div id="CotacaoCadastro" class="py-3 px-0 ">
    <!-- Cabeçalho - Titulo / Botão Editar-->
    <v-toolbar-title
      v-bind:style="{ color: COR_SUBTITULO }"
      class="d-flex align-center justify-space-between body-1 font-primary ml-4"
    >
      <p
        class="font-primary font-weight-bold font-weight-medium body-1"
        v-bind:style="{ color: COR_SUBTITULO }"
      >
        Dados Básicos
      </p>
      <v-btn
        v-if="store_Cotacao.acao == 'C'"
        @click="
          store_Cotacao.acao = 'E';
          cotacao_selecionadaAux = {
            ...store_Cotacao.cotacao_selecionada,
          };
        "
        class="btn mr-4 pr-6"
        style="background-color: #dce1eb"
        elevation="0"
      >
        <v-icon class="btn-icon mr-1" color="light-blue accent-2 "
          >mdi-view-headline</v-icon
        >
        <span
          class="caption font-weight-medium"
          v-bind:style="{ color: COR_SUBTITULO }"
          >Editar</span>
      </v-btn>
    </v-toolbar-title>

    <v-dialog
      v-model="store_Cotacao.dialogDadosBasicos"
      transition="dialog-bottom-transition"
      max-width="400"
      class="pa-0"
    ></v-dialog>

    <!-- Formulario Cadastro/Edição ----------------------------------------------------------->
    <v-form v-model="valid" id="form" ref="form" class="mx-0 mt-3">
      <v-container class="mt-2 d-flex flex-column">
        <!-- 1ª Linha - Nome / Dt Criacao / Dt Vencimento -->
        <v-row no-gutter class="pt-0 mb-n10">
          <!-- Nome da Cotação -->  
          <v-col md="6">
            <v-text-field
              v-model="store_Cotacao.cotacao_selecionada.nome_cotacao"
              :readonly="store_Cotacao.acao == 'C'"
              placeholder="Nome da Cotação"
              :rules="nomeCotacaoRules"
              label="Nome da Cotação"
              background-color="#FFF"
              loader-height="1"
              maxlength="80"
              class="mb-0"
              outlined
              required
              filled
              dense
            />
          </v-col>
          <!-- Data da Cotação -->
          <v-col md="3">
            <DatePicker
              v-model="store_Cotacao.cotacao_selecionada.dt_criacao"
              placeholder="Data de Criação"
              label="Data de Criação"
              background-color="#D0D0D0"
              loader-height="1"
              maxlength="80"
              class="mb-0"
              outlined
              readonly
              filled
              dense
            />
          </v-col>         
          <!-- Data de Vencimento -->
          <v-col md="3"> 
            <DatePicker
              v-model="store_Cotacao.cotacao_selecionada.dt_vencimento"
              :readonly="store_Cotacao.acao == 'C'"
              placeholder="Data de Vencimento"
              label="Data de Vencimento"
              background-color="#FFF"
              loader-height="1"
              maxlength="10"
              class="mb-0"
              outlined
              filled
              dense
            />
          </v-col>
        </v-row>
        <!-- 2ª Linha - Destino / Fornecedor -->
        <v-row no-gutter class="pt-0 mb-n10">
          <!-- Comprar para -->
          <v-col md="6" style="max-height 40px">
            <v-combobox
              v-model="store_Cotacao.cotacao_selecionada.CotacaoObraDeps"
              background-color="#D0D0D0"
              label="Comprar para"
              append-icon
              multiple
              outlined
              disabled
              filled
              dense
            >
              <template v-slot:selection="{item}">
                <v-chip class="mt-1" small color="#d0d0d0">
                  {{ item.nome_local.length > 20 ? item.nome_local.substring(0, 20) + '...' : item.nome_local }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
          <v-col md="6">
            <v-combobox
              v-model="store_Cotacao.cotacao_selecionada.fornecedor"
              background-color="#D0D0D0"
              label="Fornecedor"
              append-icon
              multiple
              outlined
              disabled
              filled
              dense
            >
              <template v-slot:selection="{item}">
                <v-chip class="mt-1" small color="#d0d0d0">
                    {{ item.length > 20 ? item.substring(0, 20) + '...' : item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <!-- 3ª Linha - Situação -->
        <v-row no-gutter class="pt-0 mb-n10">
          <!-- Situação -->
          <v-col md="3">
            <v-text-field
              v-model="store_Cotacao.cotacao_selecionada.situacao"
              background-color="#D0D0D0"
              placeholder="Situação"
              loader-height="1"
              label="Situação"
              maxlength="80"
              class="mb-0"
              outlined
              readonly
              filled
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- 4ª Linha - Seleção de Locais / Itens  -->
        <v-card class="mt-6" width="100%" outlined>
          <v-card-title>
            <v-btn
              @click="store_ModalCotacao.dialog_selecaoDestino = true"
              class="text-white text-capitalize "
              :color="COR_PRINCIPAL"
              elevation="0">
              <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
              <span> Incluir Item </span>
            </v-btn>
          </v-card-title>
          <v-card-text class="m-5" >
            <v-container class="ma-0 pa-0">
              <listaCompra />
            </v-container>
          </v-card-text>
        </v-card>
      </v-container>
      <!-- Rodapé - Botões Salvar / Cancelar -->
      <v-footer
        v-if="store_Cotacao.acao != 'C'"
        color="transparent"
        elevation="0"
        class="footer justify-center mt-3 pt-3 pb-n3"
        fixed
        style="position: static"
      >
        <v-btn
          v-if="store_Cotacao.acao == 'E'"
          @click="cancelar()"
          cer
          id="btn_cancelar"
          ref="btn_cancelar"
          class="mr-4 caption font-weight-medium"
          color="primary"
          text
        >
          Cancelar
        </v-btn>
        <router-link
          v-else-if="store_Cotacao.acao == 'I'"
          :to="{ name: 'CotacaoConsulta' }"
          class="nav-link"
          aria-current="page"
        >
          <v-btn
            cer
            id="btn_cancelar"
            ref="btn_cancelar"
            class="mr-4 caption font-weight-medium"
            color="primary"
            text
          >
            Cancelar
          </v-btn>
        </router-link>

        <v-btn
          :loading="loading"
          :disabled="!valid"
          @click="CadastraAtualizaCotacao()"
          class="btn caption font-weight-medium white--text"
          color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-form>

    <!-- Modal Seleção de Destino(s) -->
    <v-container v-if="store_ModalCotacao.dialog_selecaoDestino">
      <v-dialog v-model="store_ModalCotacao.dialog_selecaoDestino" scrollable max-width="350px" max-height="500px">
        <v-card>
          <!-- Dialog Cabeçalho -->
          <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">
                  Comprar para:
                </p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="store_ModalCotacao.dialog_selecaoDestino = false" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          <!-- Dialog Conteudo -->
          <v-card-text class="pa-0" style="height: 70px;">
            <v-col 
              cols="12"
              class="d-flex justify-center pa-0"
            >          
              <v-radio-group 
                mandatory
                row
                class="px-2 small-radio"
                v-model="store_Cotacao.local_tipo"
              >
                <v-radio
                  key="1"
                  label="Obra(s)"
                  value="Obra"
                  checked="checked">
                </v-radio>
                <v-radio
                  key="2"
                  label="Deposito(s)"
                  value="Deposito"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-card-text>
          <!-- Dialog Footer -->
          <v-card-actions class="d-flex justify-center pa-3" v-bind:style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn
              @click="store_ModalCotacao.dialog_selecaoDestino = false"
              class="mr-4 btn text-none"
              color="primary"
              text>
              Cancelar
            </v-btn>
            <v-btn
              class="btn text-none"
              style="width: 5.5rem"
              color="primary"
              @click="store_ModalCotacao.dialog_destinoConsulta = true">
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- Modal Seleção Obra-->
    <v-container v-if="store_ModalCotacao.dialog_destinoConsulta">
      <v-dialog
        v-model="store_ModalCotacao.dialog_destinoConsulta"
        transition="dialog-bottom-transition"
        max-width="440"
      >
        <v-card>
          <ModalDestinoConsulta />
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import store_Cotacao from "./store_Cotacao";
import store_site from "./../../../store/store_site";
import store_ModalCotacao from "./ModalCotacao/store_ModalCotacao";
import ModalDestinoConsulta from "./ModalCotacao/ModalDestinoConsulta.vue";
import ListaCompra from "../Cotacao/Tabela/ListaCompra.vue";
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import { API } from "../../../services/API";

import {
  formatNumber,
  formatDate,
  nomeIniciais,
} from "../../../services/funcoes";

import {
  COR_PRINCIPAL,
  COR_SUBTITULO,
  COR_SECUNDARIA,
  MASK_CNO,
} from "../../../services/constantes";
import { computed } from 'vue';

export default {
  name: "CotacaoCadastro",

  components: {
    ModalDestinoConsulta,
    ListaCompra,
    DatePicker,
  },

  data() {
    return {
      store_site          : store_site,
      store_Cotacao       : store_Cotacao,
      store_ModalCotacao  : store_ModalCotacao,

      MASK_CNO            : MASK_CNO,
      COR_PRINCIPAL       : COR_PRINCIPAL,
      COR_SUBTITULO       : COR_SUBTITULO,
      COR_SECUNDARIA      : COR_SECUNDARIA,
      formatNumber        : formatNumber,
      formatDate          : formatDate,
      nomeIniciais        : nomeIniciais,

      valid               : true,
      alert               : false,
      alert_msg           : "",
      loading             : false,

      dialogCancelar      : false,
      dialog_selecaoDestino: false,
      dialog_destinoConsulta: false,
      
      // Opcões de Selects estaticos
      opcoes: [{title: "Editar"},{title: "Excluir"}],
      situacao: ["Em preenchimento", "Aguardando retorno", "Aguardando análise", "Pedido concluído",],

      // Regras de Validação
      nomeCotacaoRules: [(value) => !!value || "O nome da cotação é obrigatório"],
      situacaoRules: [(value) => !!value || "A situação é obrigatória"],

      headers_enderecos: [
        { text: "Logradouro", value: "logradouro", sortable: false },
        { text: "Número", value: "numero", sortable: false },
        { text: "Bairro", value: "bairro", sortable: false },
        { text: "Cidade", value: "cidade", sortable: false },
        { text: "UF", value: "uf", sortable: false },
        { text: "CEP", value: "cep", sortable: false },
        { text: "Compl", value: "complemento", sortable: false },
      ],
            obras : [ 'Depósito Santa Maria',
                'Hidromar'
              ],

                dados : [
            {
                "insumo_cod": 1,
                "insumo_desc": "Bloco Cerâmico Vedação 9x19x19cm Cerâmica Nova Conquista",
                "insumo_valor": 23.30,
                "Obra0_Qtde": 15,
                "Obra0_ValorUnit": 25.00,
                "Obra0_DescontoUnit": 3.00,
                "Obra0_ValorTotal": 330.00,
                "Obra1_Qtde": null,
                "Obra1_ValorUnit": null,
                "Obra1_DescontoUnit": null,
                "Obra1_ValorTotal": null,
                "Total": 330.00
            },
            {
                "insumo_cod": 2,
                "insumo_desc": "Cimento CP II 50kg Votoran",
                "insumo_valor": 25.50,
                "Obra0_Qtde": null,
                "Obra0_ValorUnit": null,
                "Obra0_DescontoUnit": null,
                "Obra0_ValorTotal": null,
                "Obra1_Qtde": 20,
                "Obra1_ValorUnit": 26.50,
                "Obra1_DescontoUnit": 1.50,
                "Obra1_ValorTotal": 500.00,
                "Total": 500.00
            },
            {
                "insumo_cod": 3,
                "insumo_desc": "Areia Média 1m³",
                "insumo_valor": 85.00,
                "Obra0_Qtde": 5,
                "Obra0_ValorUnit": 86.00,
                "Obra0_DescontoUnit": 2.00,
                "Obra0_ValorTotal": 420.00,
                "Obra1_Qtde": null,
                "Obra1_ValorUnit": null,
                "Obra1_DescontoUnit": null,
                "Obra1_ValorTotal": null,
                "Total": 420.00
            },
            {
                "insumo_cod": 4,
                "insumo_desc": "Tijolo Maciço 9x19x29cm",
                "insumo_valor": 1.10,
                "Obra0_Qtde": null,
                "Obra0_ValorUnit": null,
                "Obra0_DescontoUnit": null,
                "Obra0_ValorTotal": null,
                "Obra1_Qtde": 1000,
                "Obra1_ValorUnit": 1.15,
                "Obra1_DescontoUnit": 0.05,
                "Obra1_ValorTotal": 1100.00,
                "Total": 1100.00
            },
            {
                "insumo_cod": 5,
                "insumo_desc": "Cal Hidratada 20kg",
                "insumo_valor": 8.90,
                "Obra0_Qtde": 10,
                "Obra0_ValorUnit": 9.00,
                "Obra0_DescontoUnit": 0.10,
                "Obra0_ValorTotal": 88.00,
                "Obra1_Qtde": null,
                "Obra1_ValorUnit": null,
                "Obra1_DescontoUnit": null,
                "Obra1_ValorTotal": null,
                "Total": 88.00
            },
            {
                "insumo_cod": 6,
                "insumo_desc": "Vergalhão CA-50 8mm 12m",
                "insumo_valor": 35.00,
                "Obra0_Qtde": null,
                "Obra0_ValorUnit": null,
                "Obra0_DescontoUnit": null,
                "Obra0_ValorTotal": null,
                "Obra1_Qtde": 50,
                "Obra1_ValorUnit": 36.00,
                "Obra1_DescontoUnit": 1.00,
                "Obra1_ValorTotal": 1750.00,
                "Total": 1750.00
            },
            {
                "insumo_cod": 7,
                "insumo_desc": "Telha Cerâmica Colonial 41x21cm",
                "insumo_valor": 3.20,
                "Obra0_Qtde": 200,
                "Obra0_ValorUnit": 3.25,
                "Obra0_DescontoUnit": 0.05,
                "Obra0_ValorTotal": 640.00,
                "Obra1_Qtde": null,
                "Obra1_ValorUnit": null,
                "Obra1_DescontoUnit": null,
                "Obra1_ValorTotal": null,
                "Total": 640.00
            },
            {
                "insumo_cod": 8,
                "insumo_desc": "Porta de Madeira Angelim 210x80cm",
                "insumo_valor": 450.00,
                "Obra0_Qtde": null,
                "Obra0_ValorUnit": null,
                "Obra0_DescontoUnit": null,
                "Obra0_ValorTotal": null,
                "Obra1_Qtde": 5,
                "Obra1_ValorUnit": 455.00,
                "Obra1_DescontoUnit": 5.00,
                "Obra1_ValorTotal": 2225.00,
                "Total": 2225.00
            },
            {
                "insumo_cod": 9,
                "insumo_desc": "Tubo PVC 50mm 6m Tigre",
                "insumo_valor": 20.00,
                "Obra0_Qtde": 25,
                "Obra0_ValorUnit": 20.50,
                "Obra0_DescontoUnit": 0.50,
                "Obra0_ValorTotal": 487.50,
                "Obra1_Qtde": null,
                "Obra1_ValorUnit": null,
                "Obra1_DescontoUnit": null,
                "Obra1_ValorTotal": null,
                "Total": 487.50
            },
            {
                "insumo_cod": 10,
                "insumo_desc": "Argamassa ACII 20kg Quartzolit",
                "insumo_valor": 22.00,
                "Obra0_Qtde": null,
                "Obra0_ValorUnit": null,
                "Obra0_DescontoUnit": null,
                "Obra0_ValorTotal": null,
                "Obra1_Qtde": 40,
                "Obra1_ValorUnit": 22.50,
                "Obra1_DescontoUnit": 0.50,
                "Obra1_ValorTotal": 880.00,
                "Total": 880.00
            }
        ]
    };
  },

  watch: {
    "store_Cotacao.cotacao_selecionada"(val) {
      this.atualizaAbas();
    },
  },

  async mounted() {
  },

  methods: {
    async atualizaAbas() {
      if (
        !!this.store_Cotacao.cotacao_selecionada?.cod_obra &&
        this.store_Cotacao.cotacao_selecionada?.menu_abas
      ) {
        let abas = await this.store_Cotacao.menuAbas();
        this.store_Cotacao.abas = abas;
      }
    },

    // Métodos da Obra
    async CadastraAtualizaCotacao() {
      const lb_valido = this.$refs.form.validate();
      if(lb_valido) {
        let ls_Rel = null;
        var lo_params = {...JSON.parse(JSON.stringify(this.store_Cotacao.cotacao_selecionada))};
        if (this.store_Cotacao.acao == "I") {
          ls_Rel = await this.store_Cotacao.CotacaoPost(lo_params);
          this.store_Cotacao.cotacao_selecionada.cod_cotacao = ls_Rel.data.result.data.cod_cotacao;
        }
        else if (this.store_Cotacao.acao == "E") {
          ls_Rel = await this.store_Cotacao.CotacaoPut(lo_params);
        }
        if (ls_Rel.status == 200 ) {
          this.store_Cotacao.acao = "C";
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 1500;
          this.store_site.alert_msg = ls_Rel.data.result.result;
          this.store_site.alert = true;
        }
        else {
          this.store_site.alert_cor = "#FF0000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Rel.data.result;
          this.store_site.alert = true;
        }
      }
    },

    cancelar() {
      if (this.store_Cotacao.acao == "I") {
        this.cotacao_selecionadaAux = null;
      } else if (this.store_Cotacao.acao == "E") {
        this.store_Cotacao.cotacao_selecionada = {
          ...this.cotacao_selecionadaAux,
        };
      }
      this.store_Cotacao.acao = "C";
    },

    ModalConfirmacao(item){
      this.dialogCancelar = true;
    },

    fecha_alert() {
      this.alert = false;
    },
    updateValorPrevisto(newValue) {
      // Remove a formatação para salvar o valor numérico
      const numericValue = Number(newValue.replace(/\D/g, "")) / 100;
      this.store_Cotacao.cotacao_selecionada.valor_previsto = numericValue;
    },
    updateValorRealizado(newValue) {
      // Remove a formatação para salvar o valor numérico
      const numericValue = Number(newValue.replace(/\D/g, "")) / 100;
      this.store_Cotacao.cotacao_selecionada.valor_realizado = numericValue;
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
    formattedValorPrevisto() {
      // Formata o valor para exibição
      let valor = this.store_Cotacao.cotacao_selecionada.valor_previsto;
      return formatNumber(valor);
    },
    formattedValorRealizado() {
      // Formata o valor para exibição
      let valor = this.store_Cotacao.cotacao_selecionada.valor_realizado;
      return formatNumber(valor);
    },
  },
};
</script>

<style scoped>
#CotacaoCadastro {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media (max-width: 599px) {
  #CotacaoCadastro {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#CotacaoCadastro::-webkit-scrollbar {
  width: 5px;
}

#CotacaoCadastro::-webkit-scrollbar-button {
  padding: 1px;
}

#CotacaoCadastro::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 50px;
}

#CotacaoCadastro::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
  height: 150px;
}

@media (max-width: 599px) {
  #CotacaoCadastro::-webkit-scrollbar {
    width: initial;
  }

  #CotacaoCadastro::-webkit-scrollbar-button {
    padding: initial;
  }

  #CotacaoCadastro::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #CotacaoCadastro::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  }
}

.nav-link {
  text-decoration: none;
}

.btn {
  width: 100px;
}

.btn-icon-plus {
  font-size: 20px;
}

.icon-action {
  font-size: 20px !important;
  color: #9e9e9e;
}

.flex-column {
  flex-direction: column !important;
}

@media (max-width: 599px) {
  .col-terceira-pf div:nth-child(2) {
    margin-top: -16px;
  }

  .col-terceira-pf div:nth-child(3) {
    margin-top: -12px;
  }

  .input-nome {
    margin-bottom: -8px !important;
  }

  .input-date {
    margin-top: -12px;
  }

  .input-conjuge,
  .input-date-cas {
    margin-top: -8px;
  }
}

.icon-menu {
  font-size: 20px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA) !important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px !important;
}

.theme--light.v-list-item--active:before,
.theme--light.v-list-item--active:hover:before,
.theme--light.v-list-item:focus:before {
  color: #ff0000;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}
</style>
